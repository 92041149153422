/* global $ */

class App
{
    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initFontAwesome();
        this.initMatrixContent();
        this.initStickyNav();
        this.initConversionTracking();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyNav();
        });
        console.log('App initialized');
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .dropdown-toggle').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"),(i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    initToasts(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Toast(elem);
            });
        });
    }

    initTooltips(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Tooltip(elem);
            });
        });
    }

    initMatrixContent()
    {
        if( $('.matrix-block').length) {
            import (/* webPackChunkName: "matrixContent" */ './components/matrixContent');
        } 
    }
    initStickyNav() {
        var scrollTop = $(window).scrollTop();
        if (scrollTop >= 200) {
            $('header').addClass('sticky');
        } else {
            $('header').removeClass('sticky');
        }
    }

    initConversionTracking()
    {
        $("a").each(function () {
            var parentHtml = $(this).parent().html();
            var hrefValue = $(this).attr("href");
            if (hrefValue && hrefValue.indexOf("tel:") != -1) {
                var temp = 'href="' + hrefValue + '"';
                var result = parentHtml.replace(temp, 'onclick="phoneCallConversion();" ' + 'href="' + hrefValue + '"');
                $(this).parent().html(result);
            }
        });
    }
}

export default App;